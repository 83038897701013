var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.field.component === 'VCombobox' ? _c(_vm.field.component, _vm._g(_vm._b({
    tag: "component",
    attrs: {
      "label": _vm.field.label,
      "search-input": _vm.value
    },
    on: {
      "input": function (event) {
        return _vm.$emit('input', event);
      }
    }
  }, 'component', _vm.attrs, false), _vm.$listeners)) : _c(_vm.field.component, _vm._g(_vm._b({
    tag: "component",
    attrs: {
      "label": _vm.field.label,
      "value": _vm.value
    }
  }, 'component', _vm.attrs, false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }