import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-8 d-flex align-center flex-wrap"
  }, [_c('div', {
    staticClass: "py-1"
  }, [_c('h1', [_vm._v(_vm._s(_vm.title))]), _vm.subtitle ? _c('div', [_vm._v(_vm._s(_vm.subtitle))]) : _vm._e()]), _c(VSpacer), _c('div', {
    staticClass: "d-flex justify-end align-center flex-grow-1"
  }, [_vm._t("buttons")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }