import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c(VCard, {
    attrs: {
      "elevation": "0"
    }
  }, [_c(VDataTable, {
    attrs: {
      "loading": _vm.loading,
      "items": _vm.parkingPenalties,
      "headers": _vm.headers,
      "items-per-page": 5
    },
    scopedSlots: _vm._u([{
      key: "item.status",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c(VChip, {
          attrs: {
            "dark": "",
            "block": "",
            "color": item.status == 'Aktív' ? 'success' : 'grey',
            "text": ""
          }
        }, [_vm._v(_vm._s(item.status))])];
      }
    }, {
      key: "item.start_date",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          attrs: {
            "title": _vm._f("moment")(item.start_date, 'LL')
          }
        }, [_vm._v(" " + _vm._s(_vm._f("moment")(item.start_date, 'll')) + " ")])];
      }
    }, {
      key: "item.end_date",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          attrs: {
            "title": _vm._f("moment")(item.end_date, 'LL')
          }
        }, [_vm._v(" " + _vm._s(_vm._f("moment")(item.end_date, 'll')) + " ")])];
      }
    }, {
      key: "item.created",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          attrs: {
            "title": _vm._f("moment")(item.created, 'LLLL')
          }
        }, [_vm._v(" " + _vm._s(_vm._f("moment")(item.created, 'from')) + " ")])];
      }
    }, {
      key: "item.admin",
      fn: function (_ref5) {
        var _item$admin;
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s((_item$admin = item.admin) === null || _item$admin === void 0 ? void 0 : _item$admin.name) + " ")];
      }
    }, {
      key: "item.actions",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [item.status === 'Aktív' || item.status === 'Függőben' ? _c(VBtn, {
          staticClass: "rounded-sm",
          attrs: {
            "fab": "",
            "small": "",
            "depressed": "",
            "color": "secondary",
            "title": "Büntetés visszavonása"
          },
          on: {
            "click": function ($event) {
              return _vm.cancel(item);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-delete")])], 1) : _vm._e()];
      }
    }], null, true)
  })], 1), _c(VDivider), _c('div', {
    staticClass: "mt-4 pb-4 d-flex justify-center"
  }, [_c(VBtn, {
    attrs: {
      "color": "secondary",
      "depressed": ""
    },
    on: {
      "click": _vm.openPenaltyDialog
    }
  }, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" Új büntetés ")], 1)], 1), _vm.dialog ? _c(VDialog, {
    attrs: {
      "value": true,
      "persistent": "",
      "max-width": "480px"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm.dialog === 'new' ? _c('span', {
    staticClass: "text-h5"
  }, [_vm._v("Új büntetés kiírása")]) : _vm._e(), _vm.dialog === 'edit' ? _c('span', {
    staticClass: "text-h5"
  }, [_vm._v("Büntetés szerkesztése")]) : _vm._e()]), _c(VCardText, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-datetime-picker', {
    attrs: {
      "label": "Kezdete",
      "required": "",
      "filled": "",
      "timePicker": false,
      "error-messages": _vm.errors.start_date
    },
    model: {
      value: _vm.penalty.start_date,
      callback: function ($$v) {
        _vm.$set(_vm.penalty, "start_date", $$v);
      },
      expression: "penalty.start_date"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c(VSelect, {
    attrs: {
      "label": "Időtartam",
      "filled": "",
      "items": [{
        value: '1 week',
        text: '1 hét'
      }, {
        value: '2 week',
        text: '2 hét'
      }, {
        value: '3 week',
        text: '3 hét'
      }, {
        value: '1 month',
        text: '1 hónap'
      }],
      "error-messages": _vm.errors.interval
    },
    model: {
      value: _vm.penalty.interval,
      callback: function ($$v) {
        _vm.$set(_vm.penalty, "interval", $$v);
      },
      expression: "penalty.interval"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VTextarea, {
    attrs: {
      "label": "Indoklás",
      "rows": 3,
      "auto-grow": "",
      "hint": "A büntetett kártya tulajdonosa is megkapja e-mailben.",
      "persistent-hint": "",
      "filled": "",
      "required": "",
      "error-messages": _vm.errors.reason
    },
    model: {
      value: _vm.penalty.reason,
      callback: function ($$v) {
        _vm.$set(_vm.penalty, "reason", $$v);
      },
      expression: "penalty.reason"
    }
  })], 1)], 1)], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("Mégse")]), _c(VBtn, {
    attrs: {
      "color": "error",
      "depressed": "",
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" Büntetés! ")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }