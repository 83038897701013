<template>
  <v-autocomplete v-bind="[$props, $attrs]" v-on="$listeners">
    <template v-for="slot in ['item', 'selection']" #[slot]="data">
      <v-list-item-avatar rounded color="grey" :key="'avatar-' + slot">
        <v-img v-if="thumbSrc(data.item)" :src="thumbSrc(data.item)"></v-img>
        <v-icon v-else color="grey darken-4" size="48">mdi-account</v-icon>
      </v-list-item-avatar>
      <v-list-item-content :key="'content-' + slot">
        <v-list-item-title v-html="data.item.owner_name" />
        <v-list-item-subtitle>
          <span v-if="data.item.type_name">{{ data.item.type_name.substr(0, 1) }}</span
          >{{ data.item.card_number }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  data() {
    return {
      photoDirectoryPath: process.env.VUE_APP_API_URL.replace('/api/', '/static/card_owner_photos'),
    };
  },

  methods: {
    src(item) {
      if (item.photo) {
        return `${this.photoDirectoryPath}/${item.photo}.jpg`;
      }
      return false;
    },
    thumbSrc(item) {
      if (item.photo) {
        return `${this.photoDirectoryPath}/${item.photo}_thumb.jpg`;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-select__selections > input {
  flex-grow: 0 !important;
  width: 0px !important;
}
</style>
