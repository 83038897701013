<template>
  <v-text-field
    v-bind="$attrs"
    :key="key"
    :value="value"
    v-on="$listeners"
    @input="input"
    v-mask="mask"
  />
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number],
    },
    mask: {
      validator: (v) => true,
    },
  },

  data() {
    return {
      key: 0,
      lastValue: null,
    };
  },

  watch: {
    value: {
      handler(newVal, oldVal) {
        newVal = String(newVal);
        oldVal = String(oldVal);
        if (this.lastValue === newVal) {
          return;
        }
        if (!oldVal && newVal?.length) {
          this.key++;
        }
      },
    },
  },

  methods: {
    input(v) {
      this.lastValue = String(v);
    }
  }
};
</script>
