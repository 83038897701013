import { VApp } from 'vuetify/lib/components/VApp';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VApp, {
    style: ("background-image: url('" + (_vm.background) + "')")
  }, [_c('router-view'), _vm.$store.state.user ? _c('search') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }