<template>
  <v-data-table :headers="headers" :items="log" sort-by="created" sort-desc :items-per-page="5">
    <template #[`item.writer_name`]="{ item }">
      <router-link :to="{ name: 'UserEditor', params: { id: item.write_element_id } }">
        {{ item.writer_name }}
      </router-link>
    </template>
    <template #[`item.created`]="{ item }">
      <span :title="item.created | moment('llll')">
        {{ item.created | moment('from') }}
      </span>
    </template>
    <template #[`item.changes`]="{ item }">
      <template v-if="item.changes">
        <v-alert
          v-for="(change, i) in item.changes.access_levels"
          :key="'change_access_level-' + i"
          dense
          class="py-1 my-1"
          :type="change.props.color"
          :icon="change.props.icon"
          :title="change.props.title"
        >
          [Hozzáférési szint] {{ getAccessLevelNameByID(change.value) }}
          <v-chip class="ml-2" :color="`${change.props.color} darken-1`" small v-if="change.valid_to">Lejárat: {{ change.valid_to | moment('ll') }}</v-chip>
        </v-alert>
        <v-alert
          v-for="(change, i) in item.changes.plates"
          :key="'change_plates-' + i"
          dense
          class="py-1 my-1"
          :type="change.props.color"
          :icon="change.props.icon"
          :title="change.props.title"
        >
          [Rendszám] {{ change.value }}
        </v-alert>
        <v-alert
          v-if="item.changes.status"
          dense
          class="py-1 my-1"
          :type="item.changes.status.props.color"
          :icon="item.changes.status.props.icon"
        >
          [Státusz] {{ item.changes.status.value }}
        </v-alert>
      </template>
    </template>
  </v-data-table>
</template>

<script>
const eventLogProps = {
  add: { color: 'success', icon: 'mdi-plus', title: 'Hozzáadás' },
  remove: { color: 'error', icon: 'mdi-minus', title: 'Törlés' },
  edit: { color: 'warning', icon: 'mdi-cached', title: 'Szerkesztés' },
};

export default {
  props: ['log', 'accessLevels', 'cardStatuses'],
  data() {
    return {
      eventLogProps,
      loading: true,
      items: [],
      headers: [
        { text: 'Adminisztrátor', value: 'writer_name', sortable: false },
        { text: 'Módosítva', value: 'created', sortable: false },
        { value: 'changes', sortable: false },
      ],
    };
  },
  mounted() {
    this.parseChanges();
  },
  watch: {
    log() {
      this.parseChanges();
    },
  },
  methods: {
    parseChanges() {
      try {
        let lastAccessLevelIds = [];
        let lastPlateNumbers = [];
        let lastPlates = [];
        for (const log of this.log) {
          const params = JSON.parse(log.params);

          log.changes = {
            access_levels: [],
            plates: [],
            status: false
          };

          if (params.access_level_ids) {
            if (Array.isArray(params.access_level_ids)) {
              for (const accessLevelId of params.access_level_ids) {
                if (!lastAccessLevelIds.includes(accessLevelId)) {
                  log.changes.access_levels.push({
                    props: eventLogProps['add'],
                    value: accessLevelId,
                  });
                }
              }
              for (const accessLevelId of lastAccessLevelIds) {
                if (!params.access_level_ids.includes(accessLevelId)) {
                  log.changes.access_levels.push({
                    props: eventLogProps['remove'],
                    value: accessLevelId,
                  });
                }
              }
              lastAccessLevelIds = this.lodash.cloneDeep(params.access_level_ids) || [];
            } else {
              for (const action of ['add', 'edit', 'remove']) {
                for (const access_level_id of params.access_level_ids[action] || []) {
                  log.changes.access_levels.push({
                    props: eventLogProps[action],
                    value: access_level_id.value,
                    valid_to: access_level_id.valid_to,
                  });
                }
              }
            }
          }

          if (params.plate_numbers) {
            for (const plateNumber of params.plate_numbers) {
              if (!lastPlateNumbers.includes(plateNumber)) {
                log.changes.plates.push({
                  props: eventLogProps['add'],
                  value: plateNumber.length ? plateNumber : '[Üres rendszámmező]',
                });
              }
            }
            for (const plateNumber of lastPlateNumbers) {
              if (!params.plate_numbers.includes(plateNumber)) {
                log.changes.plates.push({
                  props: eventLogProps['remove'],
                  value: plateNumber.length ? plateNumber : '[Üres rendszámmező]',
                });
              }
            }
            lastPlateNumbers = this.lodash.cloneDeep(params.plate_numbers) || [];
          }

          if (params.plates) {
            if (Array.isArray(params.plates)) {
              for (const plate of params.plates) {
                if (!plate) {
                  continue;
                }
                if (!this.lodash.some(lastPlates, { id: plate.id })) {
                  log.changes.plates.push({
                    props: eventLogProps['add'],
                    value: plate.number || '[Üres rendszámmező]',
                  });
                } else {
                  const p = this.lodash.find(lastPlates, (e) => {
                    return _.isMatch(e, { id: plate.id });
                  });
                  if (plate.number !== p.number) {
                    log.changes.plates.push({
                      props: eventLogProps['edit'],
                      value: `${p.number || '[Üres rendszámmező]'} 🠖 ${
                        plate.number || '[Üres rendszámmező]'
                      }`,
                    });
                  }
                }
              }
              for (const plate of lastPlates) {
                if (!plate) {
                  continue;
                }
                if (!this.lodash.some(params.plates, { id: plate.id })) {
                  log.changes.plates.push({
                    props: eventLogProps['remove'],
                    value: plate.number,
                  });
                }
              }
              lastPlates = this.lodash.cloneDeep(params.plates) || [];
            } else {
              for (const action of ['add', 'edit', 'remove']) {
                for (const plate of params.plates[action] || []) {
                  log.changes.plates.push({
                    props: eventLogProps[action],
                    value:
                      action === 'edit'
                        ? `${plate.value_old || '[Üres rendszámmező]'} 🠖 ${
                            plate.value_new || '[Üres rendszámmező]'
                          }`
                        : plate.value || '[Üres rendszámmező]',
                  });
                }
              }
            }
          }

          if (params.status_id) {
            const status = this.lodash.find(this.cardStatuses, { id: params.status_id });
            log.changes.status = {
              props: eventLogProps[status.name_ === 'active' ? 'add' : 'remove'],
              value: status.name
            };
          }

          if (Object.keys(params).length === 1 && params.to_element_id) {
            log.changes.status = {
              props: eventLogProps['add'],
              value: 'Létrehozva'
            };
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    getAccessLevelNameByID(id) {
      for (const accessLevel of this.accessLevels) {
        if (accessLevel.element_id == id) {
          return accessLevel.name;
        }
      }
      return 'Nem található access level (' + id + ')';
    },
  },
};
</script>
