import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VCard, {
    staticClass: "card",
    attrs: {
      "elevation": "1"
    }
  }, [_c(VCardTitle, [_vm.icon ? _c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.icon) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.title) + " ")], 1), _c(VCardSubtitle), _c(VCardText, [_c(VRow, _vm._l(_vm.statistics, function (stat, index) {
    var _stat$to;
    return _c(VCol, {
      key: ("stat-" + index)
    }, [_c(stat.to ? 'router-link' : 'div', {
      tag: "component",
      class: {
        disabled: !stat.to
      },
      attrs: {
        "to": (_stat$to = stat.to) !== null && _stat$to !== void 0 ? _stat$to : {}
      }
    }, [_c(VSheet, {
      staticClass: "d-flex align-center justify-center",
      attrs: {
        "color": "transparent",
        "height": "50"
      }
    }, [stat.value !== undefined ? _c('div', {
      staticClass: "text-h4 font-weight-regular text-center"
    }, [_vm._v(" " + _vm._s(stat.value)), stat.unit ? _c('span', {
      staticClass: "text-subtitle-1"
    }, [_vm._v(_vm._s(stat.unit))]) : _vm._e()]) : _c(VProgressCircular, {
      attrs: {
        "indeterminate": ""
      }
    })], 1), _c('div', {
      staticClass: "text-caption text-center text--text"
    }, [_vm._v(_vm._s(stat.label))])], 1)], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }