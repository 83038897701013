<template>
  <v-combobox
    v-on="$listeners"
    v-bind="$attrs"
    :items="items"
    :loading="isLoading"
    :value="search"
    :search-input.sync="search"
    hide-no-data
    hide-selected
    :item-text="itemText"
    :item-value="itemValue"
    :return-object="false"
    @input="(event) => inputHandler(event)"
  />
</template>

<script>
import { JSONPath } from 'jsonpath-plus';

export default {
  props: {
    value: {
      default: '',
    },

    searchInput: {
      type: String,
      default: '',
    },

    url: {
      type: String,
      required: true,
    },

    arrayProperty: {
      type: String,
      required: true,
    },

    itemValue: {
      type: String,
      required: true,
    },

    itemText: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      items: [],
      isLoading: false,
      model: null,
      search: null,
      searchTimeout: null,
    };
  },

  methods: {
    inputHandler(v) {
      const value = v || {};
      this.items = [];
      // console.log(JSONPath({ path: `$.${this.itemValue}`, json: v })[0] ?? v);
      this.$emit('input', JSONPath({ path: `$.${this.itemValue}`, json: value })[0] ?? v);
    },
  },


  watch: {
    value(val) {
      this.search = val;
    },
    search(val) {
      this.$emit(val);

      if (!val) {
        return;
      }
      this.loading = false;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(async () => {
        this.isLoading = true;

        const searchParams = {
          limit: 99999,
        };
        searchParams[this.itemValue] = val;

        const items = (await this.$http.post(this.url, searchParams))[this.arrayProperty];

        // this.items = items.sort();
        this.items = items
          .filter((e) => {
            return JSONPath({ path: `$.${this.itemValue}`, json: e })[0];
          })
          .sort((a, b) => {
            a = JSONPath({ path: `$.${this.itemValue}`, json: a })[0];
            b = JSONPath({ path: `$.${this.itemValue}`, json: b })[0];
            return a - b;
          });

        this.$emit('input', val);

        this.isLoading = false;
      }, 500);
    },
  },

  // computed: {
  //   searchInputValue() {
  //     if (this.searchInput) {
  //       return this.searchInput;
  //     }
  //     if (this.value) {
  //       return JSONPath({ path: `$.${this.itemValue}`, json: this.value })[0] ?? this.value;
  //     }
  //   },
  // },
};
</script>
