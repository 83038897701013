import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import fieldsModule from './fieldsModule.js';
import UserModule from './userModule.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    fields: fieldsModule,
    user: UserModule,
  },
  state: {
    user: null,
    token: null,
    drawer: false,
    rights: null,
    search: false,
    features: [],
    darkMode: window.matchMedia('(prefers-color-scheme: dark)').matches,
    cardReaderConnected: false,
  },
  mutations: {
    openSearch(state, search) {
      state.search = true;
    },
    closeSearch(state, search) {
      state.search = false;
    },
    setUser(state, user) {
      // (user.hasRight = name => {
      //   if (!name) {
      //     return true;
      //   }
      //   const right = this.findRight(state.rights, name);
      //   if (right && (right.selected || this.lodash.find(right.children, { selected: true }))) {
      //     return true;
      //   }
      //   return false;
      // }),
      //   (user.findRight = (rights, alias) => {
      //     let result = null;

      //     for (const right of rights) {
      //       if (right.alias === alias) {
      //         result = right;
      //         break;
      //       }
      //       if (right.children) {
      //         result = this.findRight(right.children, alias);
      //         if (result) {
      //           break;
      //         }
      //       }
      //     }

      //     return result;
      //   }),
      //   (user.listRights = (rights, options, level = 1) => {
      //     const defaultOptions = {
      //       maxDepth: 3,
      //       exclude: [],
      //     };
      //     options = Object.assign(defaultOptions, options);

      //     let r = [];
      //     for (const right of rights) {
      //       if (level > options.maxDepth) {
      //         continue;
      //       }

      //       if (!options.exclude.includes(right.id)) {
      //         r.push(right);
      //       }

      //       if (right.children && right.children.length > 0) {
      //         r = [...r, ...listRights(right.children, options, level + 1)];
      //       }
      //     }
      //     return r;
      //   }),
        (state.user = user);
    },
    removeUser(state) {
      state.user = null;
    },

    setFeatures(state, features) {
      state.features = features;
    },

    setUserConfig(state, config) {
      state.user.config = JSON.parse(config || "{}");
    },

    setRights(state, rights) {
      state.rights = rights;
    },
    removeRights(state) {
      state.rights = null;
    },

    setToken(state, token) {
      state.token = token;
    },
    removeToken(state) {
      state.token = null;
    },

    setDrawer(state, value) {
      state.drawer = value;
    },

    toggleDarkMode(state) {
      state.darkMode = !state.darkMode;
      window.vue.$vuetify.theme.dark = state.darkMode;
    },

    setCardReaderConnected(state, value) {
      state.cardReaderConnected = value;
    }
  },
  getters: {
    config(state) {
      return state.user?.config || {};
    },
  },
  methods: {
    // hasRight(name) {
    //   console.log("hasRIght vuex");
    //   if (!name) {
    //     return true;
    //   }
    //   const right = this.findRight(this.$store.state.rights, name);
    //   if (right && (right.selected || this.lodash.find(right.children, { selected: true }))) {
    //     return true;
    //   }
    //   return false;
    // },

    findRight(rights, alias, selected = false) {
      let result = null;

      for (const right of rights) {
        if (right.alias === alias) {
          result = right;
          break;
        }
        if (right.children) {
          result = this.findRight(right.children, alias);
          if (result) {
            break;
          }
        }
      }

      return result;
    },
  },
  plugins: [createPersistedState()],
});
