import { VAlert } from 'vuetify/lib/components/VAlert';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VDataTable, {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.log,
      "sort-by": "created",
      "sort-desc": "",
      "items-per-page": 5
    },
    scopedSlots: _vm._u([{
      key: "item.writer_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'UserEditor',
              params: {
                id: item.write_element_id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.writer_name) + " ")])];
      }
    }, {
      key: "item.created",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          attrs: {
            "title": _vm._f("moment")(item.created, 'llll')
          }
        }, [_vm._v(" " + _vm._s(_vm._f("moment")(item.created, 'from')) + " ")])];
      }
    }, {
      key: "item.changes",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.changes ? [_vm._l(item.changes.access_levels, function (change, i) {
          return _c(VAlert, {
            key: 'change_access_level-' + i,
            staticClass: "py-1 my-1",
            attrs: {
              "dense": "",
              "type": change.props.color,
              "icon": change.props.icon,
              "title": change.props.title
            }
          }, [_vm._v(" [Hozzáférési szint] " + _vm._s(_vm.getAccessLevelNameByID(change.value)) + " "), change.valid_to ? _c(VChip, {
            staticClass: "ml-2",
            attrs: {
              "color": ((change.props.color) + " darken-1"),
              "small": ""
            }
          }, [_vm._v("Lejárat: " + _vm._s(_vm._f("moment")(change.valid_to, 'll')))]) : _vm._e()], 1);
        }), _vm._l(item.changes.plates, function (change, i) {
          return _c(VAlert, {
            key: 'change_plates-' + i,
            staticClass: "py-1 my-1",
            attrs: {
              "dense": "",
              "type": change.props.color,
              "icon": change.props.icon,
              "title": change.props.title
            }
          }, [_vm._v(" [Rendszám] " + _vm._s(change.value) + " ")]);
        }), item.changes.status ? _c(VAlert, {
          staticClass: "py-1 my-1",
          attrs: {
            "dense": "",
            "type": item.changes.status.props.color,
            "icon": item.changes.status.props.icon
          }
        }, [_vm._v(" [Státusz] " + _vm._s(item.changes.status.value) + " ")]) : _vm._e()] : _vm._e()];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }