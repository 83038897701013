import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VTooltip, {
    attrs: {
      "disabled": !_vm.formattedHoverValue,
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_vm._v(" " + _vm._s(_vm.formattedValue) + " ")])];
      }
    }])
  }, [_vm._v(" " + _vm._s(_vm.formattedHoverValue) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }