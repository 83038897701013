import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VExpansionPanels, {
    staticClass: "mb-4",
    attrs: {
      "mandatory": _vm.isMandatory
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, [_c('span', [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v(_vm._s(_vm.titleIcon))]), _vm._v(" " + _vm._s(_vm.title) + " ")], 1)]), _c(VExpansionPanelContent, [_c(VRow, {
    staticClass: "mt-2"
  }, [_vm._t("default")], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }