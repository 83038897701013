export const cardReader = {
  data() {
    return {
      socket: null,
      socketError: null,
    };
  },

  methods: {
    watchCardReader(onmessageCallback) {
      if (!this.hasFeature('CARD_READER')) {
        return;
      }
      if (this.$vuetify.breakpoint.mobile) {
        return;
      }
      this.closeCardReaderSocket();

      try {
        this.socket = new WebSocket('wss://kmr-reader.uninet.hu:8080');
        this.socket.onopen = (event) => {
          this.socketError = false;
          this.$store.commit('setCardReaderConnected', true);
        };
        this.socket.onmessage = onmessageCallback;
        this.socket.onerror = (event) => {
          this.$store.commit('setCardReaderConnected', false);
          if (!this.socketError) {
            // this.$dialog.notify.error('Nincs kapcsolat a kártyaolvasó szolgáltatással!');

          }
          this.socketError = true;
          setTimeout(this.watchCardReader, 2000);
        };
      } catch (err) {
        console.error(err);
      }
    },

    closeCardReaderSocket() {
      this.$store.commit('setCardReaderConnected', false);
      if (this.socket?.readyState === WebSocket.OPEN) {
        this.socket.close();
      }
    }
  },
};
