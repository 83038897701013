<template>
  <v-expansion-panels
    class="mb-4"
    :mandatory="isMandatory"
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span>
          <v-icon left>{{ titleIcon }}</v-icon>
          {{ title }}
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="mt-2">
          <slot/>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    search: {
      type: Object,
      default: () => {},
      required: true,
    },

    title: {
      type: String,
      default: 'Szűrés',
    },

    titleIcon: {
      type: String,
      default: 'mdi-filter',
    },

    mandatory: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    isMandatory() {
      return !!Object.values(this.search).find((e) => e && e !== '' && e.length) || this.mandatory;
    }
  }
}
</script>
