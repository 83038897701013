/* eslint-disable no-unused-vars */
import 'babel-polyfill';
import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import './menu';
import http from "@/http";
import "@/directives";
import '@/mixins';
import "@/filters";
import "@/components";
Vue.prototype.$http = http;
import router from './router';
import { vuetify } from '@/plugins';
Vue.prototype.$dialogs = {};

import colors from 'vuetify/lib/util/colors';
const theme = {
  primary: '#FECB37',
  secondary: '#BB8F3B',
  accent: '#BB8F3B',
  white: colors.grey.darken4,
  grey: colors.grey.darken3,
  muted: '#272727',
  success: colors.green.darken1,
  error: colors.red.darken2,
  warning: colors.amber.darken3,
};


vuetify.framework.theme.themes['light'] = theme;
vuetify.framework.theme.themes['dark'] = theme;
setTimeout(() => {
  vuetify.framework.theme.dark = true;
});

import config from '@/config';
import variantConfig from './config';
Vue.prototype.$config = { ...config, ...variantConfig };

Vue.config.productionTip = false;

Vue.prototype.$logout = async ({ message, redirect }) => {
  await window.vue.$http.get('auth/logout');
  window.vue.$router.push({ name: 'Login', params: { message, redirect: redirect || window.vue.$route.fullPath } });
};


window.vue = new Vue({
  data() {
    return {
      user: {}
    };
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
