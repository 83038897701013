<template>
  <div class="py-8 d-flex align-center flex-wrap">
    <div class="py-1">
      <h1>{{ title }}</h1>
      <div v-if="subtitle">{{ subtitle }}</div>
    </div>

    <v-spacer />

    <div class="d-flex justify-end align-center flex-grow-1">
      <slot name="buttons"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
    },
  },
};
</script>
