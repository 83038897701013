import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c(VAvatar, {
    attrs: {
      "size": _vm.size,
      "color": _vm.color,
      "rounded": _vm.rounded
    },
    on: {
      "mouseover": function ($event) {
        _vm.hover = true;
      },
      "mouseleave": function ($event) {
        _vm.hover = false;
      }
    }
  }, [_vm.hash ? _c(VImg, {
    attrs: {
      "src": ((_vm.staticPath) + "/static/card_owner_photos/" + (_vm.hash) + "_thumb.jpg")
    }
  }) : _c(VIcon, {
    attrs: {
      "size": _vm.size * 0.8
    }
  }, [_vm._v("mdi-account")])], 1), _c(VCard, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hover,
      expression: "hover"
    }],
    staticClass: "picture"
  }, [_c(VImg, {
    attrs: {
      "src": ((_vm.staticPath) + "/static/card_owner_photos/" + (_vm.hash) + ".jpg")
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }