import { VCombobox } from 'vuetify/lib/components/VCombobox';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VCombobox, _vm._g(_vm._b({
    attrs: {
      "items": _vm.items,
      "loading": _vm.isLoading,
      "value": _vm.search,
      "search-input": _vm.search,
      "hide-no-data": "",
      "hide-selected": "",
      "item-text": _vm.itemText,
      "item-value": _vm.itemValue,
      "return-object": false
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      },
      "input": function (event) {
        return _vm.inputHandler(event);
      }
    }
  }, 'v-combobox', _vm.$attrs, false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }