import Vue from 'vue';

Vue.mixin({
  methods: {
    listRights(rights, options, level = 1) {
      const defaultOptions = {
        maxDepth: 3,
        exclude: [],
      };
      options = Object.assign(defaultOptions, options);

      let r = [];
      for (const right of rights) {
        if (level > options.maxDepth) {
          continue;
        }

        if (!options.exclude.includes(right.id)) {
          r.push(right);
        }

        if (right.children && right.children.length > 0) {
          r = [
            ...r,
            ...this.listRights(right.children, options, level + 1)
          ];
        }
      }
      return r;
    },

    hasRight(name) {
      if (!name) {
        return true;
      }

      if (name === 'SUPERADMIN' && this.$store.state.user.superadmin) {
        return true;
      }

      const right = this.findRight(this.$store.state.rights, name);
      if (right && (right.selected || this.lodash.find(right.children, { selected: true }))) {
        return true;
      }
      return false;
    },

    findRight(rights, alias, selected = false) {
      let result = null;

      for (const right of rights) {
        if (right.alias === alias) {
          result = right;
          break;
        }
        if (right.children) {
          result = this.findRight(right.children, alias);
          if (result) {
            break;
          }
        }
      }

      return result;
    },
  },
});
