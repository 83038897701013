<template>
  <v-dialog :value="true" persistent max-width="480">
    <v-card>
      <v-card-title>
        <v-icon left>mdi-format-columns</v-icon>
        Oszlopok kiválasztása
        <v-spacer />
        <v-btn icon @click="$emit('close')">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text> </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" large depressed @click="save" :loading="loading">
          Fájl exportálása
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      required: true,
    }
  },

  data() {
    return {
      selectedColums: [],
      loading: false,
    };
  },

  methods: {
    save() {
      this.$emit('save', this.selectedColums);
    },
  },
};
</script>
