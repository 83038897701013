import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VBtn, _vm._g(_vm._b({
    staticClass: "my-1 ml-2",
    attrs: {
      "color": _vm.$attrs.color || 'primary'
    }
  }, 'v-btn', _vm.$attrs, false), _vm.$listeners), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }