import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VAutocomplete, _vm._g(_vm._b({
    scopedSlots: _vm._u([_vm._l(['item', 'selection'], function (slot) {
      return {
        key: slot,
        fn: function (data) {
          return [_c(VListItemAvatar, {
            key: 'avatar-' + slot,
            attrs: {
              "rounded": "",
              "color": "grey"
            }
          }, [_vm.thumbSrc(data.item) ? _c(VImg, {
            attrs: {
              "src": _vm.thumbSrc(data.item)
            }
          }) : _c(VIcon, {
            attrs: {
              "color": "grey darken-4",
              "size": "48"
            }
          }, [_vm._v("mdi-account")])], 1), _c(VListItemContent, {
            key: 'content-' + slot
          }, [_c(VListItemTitle, {
            domProps: {
              "innerHTML": _vm._s(data.item.owner_name)
            }
          }), _c(VListItemSubtitle, [data.item.type_name ? _c('span', [_vm._v(_vm._s(data.item.type_name.substr(0, 1)))]) : _vm._e(), _vm._v(_vm._s(data.item.card_number) + " ")])], 1)];
        }
      };
    })], null, true)
  }, 'v-autocomplete', [_vm.$props, _vm.$attrs], false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }