import router from '@/router';

const ZoneList = () => import(/* webpackChunkName: "ZoneList" */ '@/pages/zones/List.vue');
const Home = () => import(/* webpackChunkName: "ZoneList" */ '../pages/home/Home.vue');

const routes = [
  {
    name: 'Login',
    meta: {
      background: 'variants/direktguard/assets/login-bg.jpg',
      logo: 'variants/direktguard/assets/logo.svg',
      subtitle: 'DirektGuard beléptető adminisztrációs felület'
    },
  },
  {
    path: '',
    component: Home,
    name: 'Home',
  },
  {
    path: '/info/status',
    component: ZoneList,
    name: 'ZoneListPage',
  },
];

router.$addRoutes(routes);

export default router;
