import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VOverlay, {
    attrs: {
      "value": true,
      "absolute": "",
      "opacity": "0.75",
      "color": "white"
    }
  }, [_c(VProgressCircular, {
    attrs: {
      "id": "loader",
      "indeterminate": "",
      "color": "primary",
      "size": "96"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }