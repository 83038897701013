import Vue from 'vue';
import JSONFormatter from 'json-formatter-js';



Vue.filter('prettifyJSON', function (value) {
  try {
    return new JSONFormatter(JSON.parse(value)).render();
  }
  catch (e) {
    console.error(`prettifyJSON filter: ${e}`);
    
    return `A JSON objektum formátuma hibás.`;
  }
})