import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VDialog, {
    attrs: {
      "value": _vm.$store.state.search,
      "max-width": "480"
    },
    on: {
      "input": function (v) {
        return !v ? _vm.$store.commit('closeSearch') : '';
      }
    }
  }, [_c(VCard, [_c(VCardTitle, [_c(VTextField, {
    attrs: {
      "prepend-inner-icon": "mdi-magnify",
      "loading": _vm.loading,
      "height": "50",
      "filled": "",
      "placeholder": "Keresés...",
      "hint": _vm.hint,
      "persistent-hint": "",
      "autofocus": "",
      "clearable": ""
    },
    on: {
      "input": _vm.inputEventHandler,
      "click:clear": function ($event) {
        _vm.keyphrase = '';
        _vm.inputEventHandler();
      }
    },
    model: {
      value: _vm.keyphrase,
      callback: function ($$v) {
        _vm.keyphrase = $$v;
      },
      expression: "keyphrase"
    }
  })], 1), _vm.results.length ? _c(VCardText, [_c(VList, [_vm._l(_vm.results, function (result) {
    return [_c(VListItem, {
      key: result.name
    }, [_c(VListItemContent, [_c(VListItemTitle, {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(result.name) + " (" + _vm._s(result.count) + " találat) ")]), result.items.length < result.count ? _c(VAlert, {
      attrs: {
        "type": "warning",
        "dense": "",
        "text": "",
        "icon": "mdi-information-outline"
      }
    }, [_c('span', [_vm._v(" 100 találat megjelenítve. Szűkítse a keresési feltételeket! ")])]) : _vm._e(), _c(VList, {
      attrs: {
        "nav": ""
      }
    }, _vm._l(result.items, function (item) {
      return _c(VListItem, {
        key: item.id,
        attrs: {
          "link": "",
          "to": {
            name: _vm.getRouteName(result.model_name),
            params: {
              id: item.id
            }
          }
        },
        on: {
          "click": function ($event) {
            return _vm.$store.commit('closeSearch');
          }
        }
      }, [item.avatar !== undefined ? _c(VListItemAvatar, [item.avatar ? _c(VImg, {
        attrs: {
          "src": ((_vm.staticPath) + "/static/card_owner_photos/" + (item.avatar) + ".jpg")
        }
      }) : _c(VIcon, [_vm._v("mdi-account")])], 1) : _vm._e(), _c(VListItemContent, {
        staticClass: "pa-1"
      }, [_c(VListItemTitle, {
        domProps: {
          "innerHTML": _vm._s(item.title)
        }
      }), item.subtitle ? _c(VListItemSubtitle, {
        domProps: {
          "innerHTML": _vm._s(item.subtitle)
        }
      }) : _vm._e()], 1)], 1);
    }), 1)], 1)], 1)];
  })], 2)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }