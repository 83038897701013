import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VCard, _vm._b({
    class: {
      'grey': _vm.dragover
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.fileInput.click();
      },
      "drop": function ($event) {
        $event.preventDefault();
        return _vm.onDrop.apply(null, arguments);
      },
      "dragover": function ($event) {
        $event.preventDefault();
        _vm.dragover = true;
      },
      "dragenter": function ($event) {
        $event.preventDefault();
        _vm.dragover = true;
      },
      "dragleave": function ($event) {
        $event.preventDefault();
        _vm.dragover = false;
      }
    }
  }, 'v-card', _vm.$attrs, false), [_c(VCardText, [_c('input', {
    ref: "fileInput",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "multiple": _vm.multiple,
      "accept": _vm.accept
    },
    on: {
      "change": _vm.onFileInputChange
    }
  }), _c(VRow, {
    staticClass: "d-flex flex-column",
    attrs: {
      "dense": "",
      "align": "center",
      "justify": "center"
    }
  }, [_c(VIcon, {
    class: [_vm.dragover ? 'mt-2, mb-6' : 'mt-5'],
    attrs: {
      "size": "60"
    }
  }, [_vm._v(" mdi-cloud-upload ")]), _c('p', [_vm._v(_vm._s(_vm.label))])], 1), _vm.files.length > 0 ? _c(VVirtualScroll, {
    attrs: {
      "items": _vm.files,
      "height": _vm.multiple ? 130 : 60,
      "item-height": "50"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c(VListItem, {
          key: item.name
        }, [_c(VListItemContent, [_c(VListItemTitle, [_vm._v(" " + _vm._s(item.name) + " "), _c('span', {
          staticClass: "ml-3 text--secondary"
        }, [_vm._v(" " + _vm._s(_vm.sizeUnitFunction(item.size)))])])], 1), _c(VListItemAction, [_c(VBtn, {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.removeFile(item.name);
            }
          }
        }, [_c(VIcon, [_vm._v(" mdi-close-circle ")])], 1)], 1)], 1), _vm.multiple ? _c(VDivider) : _vm._e()];
      }
    }], null, false, 1600326413)
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }