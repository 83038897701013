import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "bg-overlay"
    }
  }, [_c('Sidebar'), _c('Header'), _c(VMain, {
    attrs: {
      "app": ""
    }
  }, [_c('div', {
    style: {
      'height': _vm.containerHeight
    },
    attrs: {
      "id": "scroll-container"
    }
  }, [_c(VContainer, {
    staticClass: "px-2 px-sm-10",
    style: {
      'min-height': _vm.containerHeight
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('router-view'), _c(VSheet, {
    attrs: {
      "color": "transparent",
      "height": ("calc(" + (_vm.footerHeight) + " + 40px)")
    }
  }), _c('Footer')], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }