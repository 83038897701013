import Vue from 'vue';

// As a plugin
import VueMask from 'v-mask';
Vue.use(VueMask, {
  placeholders: {
    '_': /[0-9a-zA-ZáéíóäöőúüÁÉÍÓÖŐÚÜ& ._-]/,
    '-': /[0-9a-zA-ZöüÖÜ]/,
    'H': /[0-9a-fA-F]/,
    '$': /[[:ascii:]]/,
    '*': /[a-zA-Z0-9§'"+!%/=()~\\|<>#&@{},._-]/,
    '~': /\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b/,
  }
});
